<script setup lang="ts">
import { useWindowSize } from '@vueuse/core'
import {
  useSettingForPageBuild,
  useStoresForPageBuild,
  usePrefecturesForPageBuild,
} from '~/composables/useDataForPageBuild'
import { usePrefectures } from '~/composables/usePrefectures'
import { useBreadcrumb } from '~/composables/useBreadcrumb'
import { useI18n } from 'vue-i18n'

const { $breakpoint } = useNuxtApp()
const { width } = useWindowSize()
const styles = useCssModule()
const localePath = useLocalePath()
const { t } = useI18n()

// マスター設定
const { data: settingData, error: settingError } =
  await useSettingForPageBuild()
if (settingError.value) {
  console.error(settingError.value)
  throw settingError.value
}
const setting = computed(() => settingData.value)

// meta 情報
useSeoMeta({
  title: setting.value?.layout.searchesTopTitle,
  description: setting.value?.layout.searchesTopDescription,
  ogTitle: setting.value?.layout.searchesTopTitle,
  ogDescription: setting.value?.layout.searchesTopDescription,
})

// 店舗一覧
const { data: storesData, error: storesError } = await useStoresForPageBuild()
if (storesError.value) {
  console.error(storesError.value)
  throw storesError.value
}
// 都道府県
const { data: prefecturesData, error: prefecturesError } =
  await usePrefecturesForPageBuild()
if (prefecturesError.value) {
  console.error(prefecturesError.value)
  throw prefecturesError.value
}

// エリア情報
const { areas } = await usePrefectures(storesData, prefecturesData)

// エリアの開閉状態
const shownAreaIds = ref<number[]>([])
const showPcAreas = computed<boolean>(() => shownAreaIds.value.length !== 0)

// エリアの開閉処理
const toggleArea = (id: number) => {
  if (shownAreaIds.value.includes(id)) {
    const index = shownAreaIds.value.indexOf(id)
    shownAreaIds.value.splice(index, 1)
  } else {
    if (width.value >= $breakpoint) {
      shownAreaIds.value.splice(0)
    }
    shownAreaIds.value.push(id)
  }
}

// ウィンドウサイズ変更時にエリアの開閉状態を初期化
watch(
  width,
  (newValue, oldValue) => {
    if (newValue === oldValue) return
    if (oldValue && oldValue < $breakpoint && newValue >= $breakpoint) {
      shownAreaIds.value.splice(0)
    }
  },
  { immediate: true },
)

// 検索条件
const searchContentAttributes = computed(() => {
  return setting.value?.contentAttributes.filter((attr) => attr.useSearch) ?? []
})

// 条件の開閉状態
const showConditionLabels = ref<string[]>([])

// 条件の開閉処理
const toggleCondition = (label: string) => {
  if (showConditionLabels.value.includes(label)) {
    const index = showConditionLabels.value.indexOf(label)
    showConditionLabels.value.splice(index, 1)
  } else {
    showConditionLabels.value.push(label)
  }
}

// ウィンドウサイズ変更時に条件の開閉状態を初期化
watch(
  width,
  (newValue, oldValue) => {
    if (newValue === oldValue) return

    showConditionLabels.value.splice(0)
    if (newValue >= $breakpoint) {
      showConditionLabels.value.push(t('common.brand'))

      if (setting.value) {
        searchContentAttributes.value.forEach((attribute) => {
          showConditionLabels.value.push(attribute.label)
        })
      }
    }
  },
  { immediate: true },
)

// キーワードの値
const keywordParam = ref<string>('')

// ブランドの値
const brandParams = ref<number[]>([])
const changeBrandParams = (id: number, $event: Event) => {
  const target = $event.target as HTMLInputElement
  if (target.checked) {
    brandParams.value.push(id)
  } else {
    brandParams.value = brandParams.value.filter((i) => i !== id)
  }
}

// その他検索条件の値
interface ContentAttributeParams {
  [key: string]: number[]
}
const contentAttributeParams = ref<ContentAttributeParams>({})
watch(
  searchContentAttributes,
  (value) => {
    if (!value) return
    value.forEach((attr) => {
      contentAttributeParams.value[attr.label] = []
    })
  },
  { immediate: true },
)
const changeContentAttributeParams = (
  key: string,
  id: number,
  $event: Event,
) => {
  const target = $event.target as HTMLInputElement
  if (target.checked) {
    contentAttributeParams.value[key].push(id)
  } else {
    contentAttributeParams.value[key] = contentAttributeParams.value[
      key
    ].filter((i) => i !== id)
  }
}

// 検索パラメータ
interface SearchParams {
  [key: string]: string
}
const searchParams = computed<SearchParams>(() => {
  const params: SearchParams = {}

  if (keywordParam.value) {
    params.keyword = keywordParam.value
  }
  if (brandParams.value.length) {
    params.brand = brandParams.value.join(',')
  }
  for (const key in contentAttributeParams.value) {
    if (contentAttributeParams.value[key].length) {
      params[key] = contentAttributeParams.value[key].join(',')
    }
  }
  return params
})

// NOTE: 日本語入力中はエンターキーを無視する。 safari では isComposing でIMEの入力か判別できないため、keyCode でチェックする。
// keyCode は非推奨となっているが、 mdn の例として利用されているため採用している。
// https://developer.mozilla.org/ja/docs/Web/API/Element/keydown_event
const handleKeydownEnter = (event: KeyboardEvent) => {
  if (event.isComposing || event.keyCode === 229) return
  search()
}

// 絞り込み
const search = () => {
  const query = new URLSearchParams({ ...searchParams.value })
  const localizedPath = localePath({ name: 'stores', path: '/stores' })
  navigateTo({ path: localizedPath, query: { ...Object.fromEntries(query) } })
}

// リセット
const reset = () => {
  keywordParam.value = ''
  brandParams.value = []
  for (const key in contentAttributeParams.value) {
    contentAttributeParams.value[key] = []
  }
}

// お知らせリンクのドメイン判定
const linkToNotice = (url: string) => {
  if (import.meta.client) {
    const target = url.includes(window.location.hostname) ? '_self' : 'blank'
    window.open(url, target)
  }
}

// 位置情報の取得
interface Position {
  coords: {
    latitude: number
    longitude: number
  }
}
const currentLatitude = ref<number>(0)
const currentLongitude = ref<number>(0)
const geoSuccess = (pos: Position) => {
  currentLatitude.value = pos.coords.latitude
  currentLongitude.value = pos.coords.longitude
}
const geoError = () => {
  console.error('Geolocation error')
}
const geoOptions = {
  enableHighAccuracy: true, // 高精度要求
  timeout: 5000, // タイムアウト
  maximumAge: 0, // キャッシュ有効期限
}
if (import.meta.client) {
  navigator.permissions.query({ name: 'geolocation' }).then((permission) => {
    if (permission.state === 'denied') return
    navigator.geolocation.getCurrentPosition(
      geoSuccess, // 成功時コールバック
      geoError, // 失敗時コールバック
      geoOptions, // オプション
    )
  })
}

// パンくずリスト
const { updateBreadcrumb } = useBreadcrumb()

onMounted(() => {
  updateBreadcrumb([
    { label: t('breadcrumb.top'), link: { name: 'index' } },
    { label: t('breadcrumb.storeSearch') },
  ])
})

// メイン画像切り替え用のブレークポイント
const mainImageBreakpoint = 768
const isMainImageSp = computed(() => {
  return width.value < mainImageBreakpoint
})

// スラッシュだと単語区切りとして扱われないので、スラッシュの前後に改行ポイントを挿入
const formatAreaName = (name: string) => {
  return name.replace(/\//g, '/<wbr>')
}
</script>

<template>
  <template v-if="setting">
    <!-- start: メイン画像 -->
    <template v-if="setting.coverImage">
      <div
        v-if="setting.coverImage.pcImageUrl && setting.coverImage.spImageUrl"
        :class="styles.mainImage"
      >
        <picture>
          <source
            :media="`(min-width: ${mainImageBreakpoint}px)`"
            :srcset="setting.coverImage.pcImageUrl"
          />
          <img
            :src="setting.coverImage.spImageUrl"
            :alt="setting.coverImage.imageAlt"
          />
        </picture>
      </div>
      <div
        v-else-if="setting.coverImage.pcImageUrl && !isMainImageSp"
        :class="styles.mainImage"
      >
        <img
          :src="setting.coverImage.pcImageUrl"
          :alt="setting.coverImage.imageAlt"
        />
      </div>
      <div
        v-else-if="setting.coverImage.spImageUrl && isMainImageSp"
        :class="styles.mainImage"
      >
        <img
          :src="setting.coverImage.spImageUrl"
          :alt="setting.coverImage.imageAlt"
        />
      </div>
    </template>
    <!-- end: メイン画像 -->

    <!-- start: エリアから探す -->
    <section
      v-if="setting.locationSearch || setting.prefectureSearch"
      :class="styles.section"
    >
      <div :class="styles.section__container">
        <h2 :class="styles.section__name">{{ t('area.searchByArea') }}</h2>
        <div :class="styles.areas">
          <NuxtLink
            v-if="setting.locationSearch"
            :to="localePath({ name: 'stores' })"
            :class="styles.areas__current"
          >
            <div :class="styles.areas__current__icon">
              <KsIcon name="marker" color="#fff" />
            </div>
            <div :class="styles.areas__current__label">
              {{ t('area.searchByNearLocation') }}
            </div>
          </NuxtLink>
          <ul v-if="setting.prefectureSearch" :class="styles.areas__items">
            <li
              v-for="area in areas"
              :key="area.id"
              :class="[
                styles.areas__item,
                shownAreaIds.includes(area.id) ? styles['is-open'] : null,
              ]"
            >
              <button
                type="button"
                :class="[
                  styles.areas__item__container,
                  area.count === 0 ? styles['is-empty'] : null,
                ]"
                @click="toggleArea(area.id)"
              >
                <span :class="styles.areas__item__icon">
                  <KsIcon name="arrowBottom" />
                </span>
                <span
                  :class="styles.areas__item__label"
                  v-html="formatAreaName(area.name)"
                />
                <span :class="styles.areas__item__count">
                  （{{ area.count }}）
                </span>
              </button>
              <KsAccordion :show="shownAreaIds.includes(area.id)">
                <ul
                  :class="[
                    styles.areas__prefectures,
                    styles['areas__prefectures--sp'],
                  ]"
                >
                  <li
                    v-for="prefecture in area.prefectures"
                    :key="prefecture.id"
                    :class="[
                      styles.areas__prefectures__item,
                      prefecture.count === 0 ? styles['is-empty'] : null,
                    ]"
                  >
                    <NuxtLink
                      :to="
                        localePath({
                          name: 'stores',
                          query: { prefecture: prefecture.id },
                        })
                      "
                      :class="styles.areas__prefectures__link"
                    >
                      <span :class="styles.areas__prefectures__name">{{
                        prefecture.name
                      }}</span>
                      <span :class="styles.areas__prefectures__count"
                        >（{{ prefecture.count }}）</span
                      >
                    </NuxtLink>
                  </li>
                </ul>
              </KsAccordion>
            </li>
          </ul>
          <KsAccordion :show="showPcAreas">
            <div :class="styles.areas__prefecturesContainer">
              <template v-for="area in areas" :key="area.id">
                <ul
                  v-show="shownAreaIds.includes(area.id)"
                  :class="[
                    styles.areas__prefectures,
                    styles['areas__prefectures--pc'],
                  ]"
                >
                  <li
                    v-for="prefecture in area.prefectures"
                    :key="prefecture.id"
                    :class="[
                      styles.areas__prefectures__item,
                      prefecture.count === 0 ? styles['is-empty'] : null,
                    ]"
                  >
                    <NuxtLink
                      :to="
                        localePath({
                          name: 'stores',
                          query: { prefecture: prefecture.id },
                        })
                      "
                      :class="styles.areas__prefectures__link"
                    >
                      <span :class="styles.areas__prefectures__name">{{
                        prefecture.name
                      }}</span>
                      <span :class="styles.areas__prefectures__count"
                        >（{{ prefecture.count }}）</span
                      >
                    </NuxtLink>
                  </li>
                </ul>
              </template>
            </div>
          </KsAccordion>
        </div>
      </div>
    </section>
    <!-- end: エリアから探す -->

    <!-- start: キーワードから探す -->
    <section v-if="setting.keywordSearch" :class="styles.section">
      <div :class="styles.section__container">
        <h2 :class="styles.section__name">
          {{ $t('search.searchByKeyword') }}
        </h2>
        <div :class="styles.keyword">
          <input
            v-model="keywordParam"
            type="text"
            :class="styles.keyword__input"
            :placeholder="$t('search.enterLocationOrStore')"
            @keydown.enter="handleKeydownEnter"
          />
          <button type="button" :class="styles.keyword__button" @click="search">
            <span :class="styles.keyword__button__icon">
              <KsIcon name="search" color="#fff" />
            </span>
          </button>
        </div>
      </div>
    </section>
    <!-- end: キーワードから探す -->

    <!-- start: 条件で絞り込んで探す -->
    <ClientOnly>
      <section
        v-if="
          searchContentAttributes.length ||
          (setting.brandSearch && setting.brands.length)
        "
        :class="styles.section"
      >
        <div :class="styles.section__container">
          <h2 :class="styles.section__name">
            {{ $t('search.searchByConditions') }}
          </h2>
          <div :class="styles.conditions">
            <!-- start: ブランド検索 -->
            <section
              v-if="setting.brandSearch && setting.brands.length"
              :class="[
                styles.conditions__section,
                showConditionLabels.includes($t('common.brand'))
                  ? styles['is-open']
                  : null,
              ]"
            >
              <h3 :class="styles.conditions__name">{{ $t('common.brand') }}</h3>
              <button
                type="button"
                :class="[styles.conditions__button]"
                @click="toggleCondition($t('common.brand'))"
              >
                {{ $t('common.brand') }}
                <span :class="styles.conditions__button__icon">
                  <KsIcon name="arrowBottom" color="#3A3B3F" />
                </span>
              </button>
              <KsAccordion
                :show="showConditionLabels.includes($t('common.brand'))"
              >
                <ul :class="styles.conditions__items">
                  <li
                    v-for="brand in setting.brands"
                    :key="brand.name"
                    :class="styles.conditions__item"
                  >
                    <input
                      :id="`brand_${brand.name}`"
                      type="checkbox"
                      :class="styles.conditions__item__checkbox"
                      :checked="brandParams.includes(brand.id)"
                      @change="changeBrandParams(brand.id, $event)"
                    />
                    <label
                      :for="`brand_${brand.name}`"
                      :class="styles.conditions__item__container"
                    >
                      <span
                        v-if="brand.imageUrl"
                        :class="styles.conditions__item__icon"
                      >
                        <NuxtImg
                          :src="brand.imageUrl"
                          sizes="(max-width: 767px) 48px, 24px"
                          alt=""
                        />
                      </span>
                      <span :class="styles.conditions__label">{{
                        brand.name
                      }}</span>
                    </label>
                  </li>
                </ul>
              </KsAccordion>
            </section>
            <!-- end: ブランド検索 -->

            <!-- start: その他検索条件 -->
            <template v-if="searchContentAttributes.length">
              <section
                v-for="attribute in searchContentAttributes"
                :key="attribute.label"
                :class="[
                  styles.conditions__section,
                  showConditionLabels.includes(attribute.label)
                    ? styles['is-open']
                    : null,
                ]"
              >
                <h3 :class="styles.conditions__name">{{ attribute.label }}</h3>
                <button
                  type="button"
                  :class="[styles.conditions__button]"
                  @click="toggleCondition(attribute.label)"
                >
                  {{ attribute.label }}
                  <span :class="styles.conditions__button__icon">
                    <KsIcon name="arrowBottom" color="#3A3B3F" />
                  </span>
                </button>
                <KsAccordion
                  :show="showConditionLabels.includes(attribute.label)"
                >
                  <ul :class="styles.conditions__items">
                    <li
                      v-for="item in attribute.list"
                      :key="item.label"
                      :class="styles.conditions__item"
                    >
                      <input
                        :id="`contentAttribute_${attribute.id}_${item.id}`"
                        type="checkbox"
                        :class="styles.conditions__item__checkbox"
                        :checked="
                          contentAttributeParams[attribute.label].includes(
                            item.id,
                          )
                        "
                        @change="
                          changeContentAttributeParams(
                            attribute.label,
                            item.id,
                            $event,
                          )
                        "
                      />
                      <label
                        :for="`contentAttribute_${attribute.id}_${item.id}`"
                        :class="styles.conditions__item__container"
                      >
                        <span
                          v-if="item.imageUrl"
                          :class="styles.conditions__item__icon"
                        >
                          <NuxtImg
                            :src="item.imageUrl"
                            sizes="(max-width: 767px) 48px, 24px"
                            :alt="item.imageAlt ?? ''"
                          />
                        </span>
                        <span :class="styles.conditions__label">{{
                          item.label
                        }}</span>
                      </label>
                    </li>
                  </ul>
                </KsAccordion>
              </section>
            </template>
            <!-- end: その他検索条件 -->

            <div :class="styles.conditions__buttons">
              <KsButton color="default" type="button" @click="reset">
                {{ $t('common.reset') }}
              </KsButton>
              <KsButton color="secondary" type="button" @click="search">
                {{ $t('search.filterByConditions') }}
              </KsButton>
            </div>
          </div>
        </div>
      </section>
    </ClientOnly>
    <!-- end: 条件で絞り込んで探す -->

    <!-- start: お知らせ -->
    <section v-if="setting.notices.length" :class="styles.section">
      <div :class="styles.section__container">
        <h2 :class="styles.section__name">{{ $t('common.notices') }}</h2>
        <dl :class="styles.notices">
          <div
            v-for="(notice, i) in setting.notices"
            :key="i"
            :class="styles.notices__item"
          >
            <dt :class="styles.notices__date">
              {{ $formatDate(notice.createdAt) }}
            </dt>
            <dd :class="styles.notices__content">
              <a
                :href="notice.url"
                :class="styles.notices__link"
                @click.prevent.stop="linkToNotice(notice.url)"
              >
                {{ notice.title }}
                <div :class="styles.notices__link__icon">
                  <KsIcon name="arrowRight" color="#3A3B3F" />
                </div>
              </a>
            </dd>
          </div>
        </dl>
      </div>
    </section>
    <!-- end: お知らせ -->
  </template>
</template>

<style lang="scss" module>
.mainImage {
  @include mq() {
    max-width: $content-max-width;
    margin: 0 auto 56px;
    padding: 0 30px;
  }
}
.section {
  padding: 48px 24px;
  &:nth-of-type(odd) {
    background-color: #f2f2f2;
  }
  &__name {
    display: flex;
    gap: 12px;
    position: relative;
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    &::before {
      transform: translateY(12px);
      width: 16px;
      height: 1px;
      background-color: var(--primary-color-code);
      content: '';
    }
  }
  @include mq() {
    padding: 48px 0;
    &__container {
      width: 100%;
      max-width: $content-max-width;
      margin: 0 auto;
      padding: 0 30px;
    }
    &__name {
      margin-bottom: 24px;
      font-size: 20px;
      line-height: 1.2;
      &::before {
        width: 24px;
      }
    }
  }
}
.areas {
  $root: #{&};
  &__current {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    min-height: 64px;
    padding: 8px 14px;
    border-radius: 4px;
    background-color: var(--secondary-color-code);
    border: 2px solid var(--secondary-color-code);
    color: #fff;
    text-decoration: none;
    transition: 0.3s;
    word-break: keep-all;
    path {
      transition: 0.3s;
    }
    @media (hover: hover) {
      &:hover {
        background-color: #fff;
        color: var(--secondary-color-code);
        path {
          fill: var(--secondary-color-code);
        }
      }
    }
    &__icon {
      flex-basis: 16px;
      min-width: 16px;
    }
    &__label {
      font-weight: bold;
      font-size: 14px;
      line-height: 1.5;
    }
  }
  &__items {
    margin-top: 24px;
  }
  &__item {
    &:not(:first-child) {
      margin-top: 10px;
    }
    &.is-open {
      #{$root}__item__container {
        background-color: #dadada;
        @media (hover: hover) {
          &:hover {
            color: #3a3b3f;
            path {
              fill: #3a3b3f;
            }
          }
        }
      }
      #{$root}__item__icon {
        transform: translateY(-50%) rotate(180deg);
      }
    }
    &__container {
      display: flex;
      align-items: center;
      gap: 8px;
      position: relative;
      width: 100%;
      min-height: 64px;
      padding: 24px 56px 24px 24px;
      border: 2px solid #e7e7e7;
      border-radius: 4px;
      background-color: #fff;
      color: #3a3b3f;
      text-decoration: none;
      white-space: nowrap;
      transition: 0.3s;
      path {
        transition: 0.3s;
      }
      @media (hover: hover) {
        &:hover {
          background-color: var(--secondary-color-code);
          color: #fff;
          path {
            fill: #fff;
          }
        }
      }
      &.is-empty {
        opacity: 0.35;
        pointer-events: none;
      }
    }
    &__icon {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      width: 16px;
    }
    &__label {
      font-weight: bold;
      font-size: 14px;
      line-height: 1.5;
      white-space: nowrap;
    }
    &__count {
      font-size: 12px;
      line-height: 1.5;
    }
  }
  &__prefectures {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    margin-top: 8px;
    padding: 24px;
    background-color: #dadada;
    &--pc {
      display: none;
    }
    &__item {
      flex-basis: calc((100% - 16px) / 2);
      font-size: 12px;
      line-height: 1.17;
      &.is-empty {
        opacity: 0.35;
        #{$root}__prefectures__name {
          text-decoration: none;
        }
        #{$root}__prefectures__link {
          pointer-events: none;
        }
      }
    }
    &__link {
      color: #3a3b3f;
      text-decoration: none;
    }
    &__name {
      text-decoration: underline;
    }
    &__count {
      margin-left: 8px;
    }
  }
  @include mq() {
    &__current {
      width: calc((100% - 32px) / 3 * 2);
    }
    &__items {
      display: flex;
      flex-wrap: wrap;
      gap: 16px 24px;
    }
    &__item {
      width: calc((100% - 48px) / 3);
      &:not(:first-child) {
        margin-top: 0;
      }
      &__container {
        justify-content: center;
        padding: 8px 48px;
      }
      &__label {
        word-break: break-word;
      }
    }
    &__prefecturesContainer {
      padding-top: 12px;
    }
    &__prefectures {
      margin: 0;
      padding: 24px 32px;
      &--pc {
        display: flex;
      }
      &--sp {
        display: none;
      }
      &__item {
        flex-basis: calc((100% - 48px) / 4);
      }
    }
  }
  @include mq(md) {
    &__current {
      width: calc((100% - 72px) / 4);
    }
    &__item {
      width: calc((100% - 72px) / 4);
    }
  }
}
.keyword {
  display: flex;
  border: 2px solid #3a3b3f;
  border-radius: 4px;
  &__input {
    flex-grow: 1;
    padding: 0 31px;
    font-size: 16px;
    &::placeholder {
      color: #777;
    }
  }
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 46px;
    background-color: #3a3b3f;
    &__icon {
      width: 16px;
    }
  }
  @include mq() {
    &__button {
      height: 44px;
    }
  }
}
.conditions {
  $root: #{&};
  &__section {
    border-radius: 4px;
    background-color: #fff;
    &:not(:first-of-type) {
      margin-top: 24px;
    }
    &.is-open {
      #{$root}__button {
        background-color: #dadada;
        &__icon {
          transform: rotate(180deg);
        }
      }
    }
  }
  &__name {
    display: none;
  }
  &__button {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 18px 16px 18px 24px;
    font-weight: bold;
    font-size: 12px;
    line-height: 1.17;
    &__icon {
      flex-basis: 16px;
      min-width: 16px;
    }
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 16px 24px;
  }
  &__item {
    flex-basis: 100%;
    font-size: 14px;
    line-height: 1.28;
    &__checkbox {
      display: none;
      &:checked + #{$root}__item__container::before {
        border-color: #4da1ff;
        background-color: #4da1ff;
      }
    }
    &__container {
      display: flex;
      align-items: center;
      gap: 8px;
      position: relative;
      min-height: 16px;
      padding-left: 24px;
      transition: 0.2s;
      cursor: pointer;
      &::before {
        position: absolute;
        top: 50%;
        left: 0%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        border: 2px solid #d5d5d5;
        border-radius: 3px;
        background-color: #fff;
        content: '';
        transition: 0.2s;
      }
      &::after {
        position: absolute;
        top: 50%;
        left: 4px;
        transform: translateY(-50%) rotate(-45deg);
        width: 8px;
        height: 4px;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        content: '';
      }
    }
    &__icon {
      flex-basis: 24px;
      min-width: 24px;
    }
  }
  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    margin-top: 24px;
  }
  @include mq() {
    &__section {
      background-color: transparent;
      &:not(:first-of-type) {
        margin-top: 32px;
      }
    }
    &__name {
      display: block;
      margin-bottom: 16px;
      font-weight: bold;
      font-size: 16px;
      line-height: 1.5;
    }
    &__button {
      display: none;
    }
    &__items {
      gap: 24px;
      padding: 0;
    }
    &__item {
      flex-basis: calc((100% - 48px) / 3);
      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-basis: 48px;
        min-width: 48px;
        min-height: 48px;
        padding: 8px;
        border-radius: 8px;
        background-color: #fff;
      }
    }
  }
  @include mq(md) {
    &__item {
      flex-basis: calc((100% - 72px) / 4);
    }
  }
}
.notices {
  &__item {
    font-size: 14px;
    line-height: 1.5;
    &:not(:first-child) {
      margin-top: 24px;
    }
  }
  &__date {
    color: #777;
  }
  &__content {
    margin-top: 8px;
  }
  &__link {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #3a3b3f;
    text-decoration: none;
    &__icon {
      flex-basis: 16px;
      transform: translateY(-1px);
      min-width: 16px;
      margin-left: 4px;
    }
  }
  @include mq() {
    &__item {
      display: flex;
      gap: 64px;
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
    &__content {
      margin-top: 0;
    }
  }
}
</style>
